import { BusinessUnitLocationsService } from './business-unit-locations.service';
import { Component } from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-business-unit-locations',
  templateUrl: './business-unit-locations.component.html',
  styleUrls: ['./business-unit-locations.component.scss']
})
export class BusinessUnitLocationsComponent {
  identifiers: any
  selectedIdentifier: any
  loggedMasterUser: any
  clientData: any

  businessUnits: any = []
  locations: any

  selectedBusinessUnit: any = ''
  selectedLocation: any = ''

  selectedBusinessUnitForEdit: any = {}
  selectedLocationForEdit: any = {}

  tree: any;
  isLoading: boolean

  constructor(private adminService: AdminService, private businessUnitLocationsService: BusinessUnitLocationsService, private toastr: ToastrService) {
    this.isLoading = true
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe((data) => {
        this.clientData = data;
        this.tree = {}
        this.tree.name = this.clientData?.name + " ( " + this.clientData?.shortName + " ) "
        this.tree.css = "background-color: #00bf63 !important;color: #fff !important;box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;"
      });
    }
  }
  ngOnInit() {
    this.getBusinessUnits()
    this.getLocations()
  }
  getBusinessUnits() {
    this.businessUnitLocationsService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data
    })

  }
  getLocations() {
    this.businessUnitLocationsService.getLocations().subscribe((data) => {
      this.locations = data
      setTimeout(() => {
        this.getOrgChart()
      }, 500)
    })
  }
  getOrgChart() {
    let businessUnit = []
    for (let item of this.businessUnits) {
      let locations = []
      for (let location of this.locations) {
        if (item.id == location.businessunit_id) {
          locations.push({
            name: this.capitalizeFirstLetter(location.identifier) + " (Location)",
            description: location.description,
            children: [],
            css: "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;"
          })
        }
      }
      businessUnit.push({
        name: this.capitalizeFirstLetter(item.identifier) + " (Business Unit)",
        description: item.description,
        children: locations,
        css: "box-shadow: 0px 0px 18px 0px rgba(0, 191, 99, 0.12) !important;"
      })
    }
    this.isLoading = false
    this.tree.children = businessUnit
  }

  setLocation(event: any) {
    if (event) this.selectedLocationForEdit = this.locations.find((item: any) => item.id == event)
    else {
      this.selectedLocation = ''
      this.selectedLocationForEdit = {}
    }
  }
  setBusinessUnit(event: any) {
    if (event) this.selectedBusinessUnitForEdit = this.businessUnits.find((item: any) => item.id == event)
    else {
      this.selectedBusinessUnit = '';
      this.selectedBusinessUnitForEdit = {}
    }
  }
  capitalizeFirstLetter(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  deleteBusinessUnit() {
    if (this.selectedBusinessUnitForEdit.status === 'active' || this.selectedBusinessUnit=='') return
    this.businessUnitLocationsService.deleteBusinessUnits(this.selectedBusinessUnitForEdit).subscribe({
      next: (data) => {
        if (data) {
          this.getBusinessUnits()
          this.getOrgChart()
          this.toastr.success('Business Unit has been deleted', 'Success');
          this.selectedBusinessUnitForEdit = {}
          this.selectedBusinessUnit = ''
        }
      },
      error: (err) => {
        this.toastr.error('An error occurred during business unit deletion', 'Error');
      }
    })
  }
  deleteLocation() {
    if (this.selectedLocationForEdit.status === 'active' || this.selectedLocation == '') return
    this.businessUnitLocationsService.deleteLocation(this.selectedLocationForEdit).subscribe({
      next: (data) => {
        if (data) {
          this.getLocations()
          this.getOrgChart()
          this.toastr.success('Location has been deleted', 'Success');
          this.selectedLocationForEdit = {}
          this.selectedLocation = ''
        }
      },
      error: (err) => {
        this.toastr.error('An error occurred during location deletion', 'Error');
      }
    })
  }
  addUpdateLocation() {
    if (this.selectedLocation) {
      this.businessUnitLocationsService.updateLocation(this.selectedLocationForEdit).subscribe({
        next: (data) => {
          if (data) {
            this.getLocations()
            this.getOrgChart()
            this.toastr.success('Location has been updated', 'Success');
            this.selectedLocationForEdit = {}
            this.selectedLocation = ''
          }
        },
        error: (err) => {
          this.toastr.error('An error occurred during location updation', 'Error');
        }
      })
    } else {
      this.businessUnitLocationsService.addLocation(Object.assign(this.selectedLocationForEdit, { 'client_id': this.loggedMasterUser.client_id })).subscribe({
        next: (data) => {
          if (data) {
            this.getLocations()
            this.getOrgChart()
            this.toastr.success('Location has been created', 'Success');
            this.selectedLocationForEdit = {}
            this.selectedLocation = ''
          }
        },
        error: (err) => {
          this.toastr.error('An error occurred during location creation', 'Error');
        }
      })
    }
  }
  addUpdateBusinessUnit() {
    if (this.selectedBusinessUnit) {
      this.businessUnitLocationsService.updateBusinessUnits(this.selectedBusinessUnitForEdit).subscribe({
        next: (data) => {
          if (data) {
            this.getBusinessUnits()
            this.getOrgChart()
            this.toastr.success('Business Unit has been updated', 'Success');
            this.selectedBusinessUnitForEdit = {}
            this.selectedBusinessUnit = ''
          }
        },
        error: (err) => {
          this.toastr.error('An error occurred during business unit updation', 'Error');
        }
      })
    } else {
      this.businessUnitLocationsService.addBusinessUnits(Object.assign(this.selectedBusinessUnitForEdit, { 'client_id': this.loggedMasterUser.client_id })).subscribe({
        next: (data) => {
          if (data) {
            this.getBusinessUnits()
            this.getOrgChart()
            this.toastr.success('Business Unit has been created', 'Success');
            this.selectedBusinessUnitForEdit = {}
            this.selectedBusinessUnit = ''
          }
        },
        error: (err) => {
          this.toastr.error('An error occurred during business unit creation', 'Error');
        }
      })
    }
  }
}
