<title>MyEnergy Login: Access Your energy control sevice market</title>
<meta
  name="description"
  content="Welcome to the MyEnergy login portal - your personal gateway to efficient management of energy. Log in to start working with your personalized dashboard, get access to real-time data and powerful optimization tools customized to your energy needs."
/>

<section>
  <form
    class=""
    action="#"
    method="get"
    ngForm
    (ngSubmit)="onSubmit()"
    #theForm="ngForm"
  >
    <section class="custom_login_section">
      <div class="login_card_wrapper">
        <div class="">
          <div class="custom_logo_section">
            <div class="logo-section">
              <img
                class="img-responsive"
                src="../../assets/logo/logo-green.png"
                alt=""
              />
            </div>
            <h3 class="logo_title">
              I Control <br />
              <span> MyEnergy </span>
            </h3>
          </div>
          <p class="" *ngIf="!forgetPassword">
            {{ "userLogin.pleaseEnterEmailPassword" | translate }}
          </p>
          <p *ngIf="forgetPassword" class="">
            {{ "userLogin.pleaseEnterEmailNewPassword" | translate }}
          </p>

          <div class="form-group">
            <input
              type="text"
              id="login"
              name="login"
              class="form-control"
              [placeholder]="'userLogin.emailAddress' | translate"
              required=""
              [(ngModel)]="user.login"
              #login="ngModel"
              required="true"
              minlength="4"
              maxlength="100"
              (ngModelChange)="isEmailValid($event)"
            />
            <div
              *ngIf="login.touched && emailError != ''"
              class="col-lg-12 invalid-input"
              [style.top.px]="errEmailY"
              [style.left.px]="errEmailX"
            >
              {{ emailError | translate }}
            </div>
            <!--
            <span
              class="validate_info"
              *ngIf="login.touched && login.invalid && login.errors?.['required']"
            >
              {{ "userLogin.emailValidationMessage" | translate }}
            </span>
            <span
              class="validate_info"
              *ngIf="login.touched && login.invalid && login.errors?.['minlength']"
            >
              *Email must be at least
              {{login.errors?.['minlength']?.['requiredLength']}} characters
              long.
            </span>
          --></div>

          <div class="form-group">
            <input
              type="password"
              id="password"
              name="password"
              class="form-control"
              [placeholder]="'userLogin.password' | translate"
              required=""
              [(ngModel)]="user.password"
              #password="ngModel"
              required
              minlength="3"
              maxlength="100"
              (ngModelChange)="isPasswordValid($event)"
            />
            <div
              *ngIf="password.touched && psswdInvalid != ''"
              class="col-lg-12 invalid-input"
              [style.top.px]="errPsswdY"
              [style.left.px]="errPsswdX"
            >
              {{ psswdInvalid | translate }}
            </div>

            <!--
            <span
              class="validate_info"
              *ngIf="password.touched && password.invalid && password.errors?.[ 'required'] "
            >
              {{ "userLogin.passwordValidationMessage" | translate }}
            </span>
            <span
              class="validate_info"
              *ngIf="password.touched && password.invalid && password.errors?.[ 'minlength'] "
            >
              *Password must be at least
              {{password.errors?.['minlength']?.['requiredLength']}}
              characters long.
            </span>
            -->
          </div>

          <div class="form-group" *ngIf="forgetPassword">
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              class="form-control"
              placeholder="Confirm Password"
              required=""
              [(ngModel)]="confirmPassword"
              #password="ngModel"
              required
            />
            <!--
            <span class="validate_info" *ngIf="password.touched && password.invalid && password.errors?.[ 'required'] ">
              *Please enter your password.
            </span> -->
          </div>

          <!-- <div class="errMessage">
            {{errMessage}}
          </div> -->

          <p class="" style="text-align: right">
            <a class="forgetBtn" *ngIf="!forgetPassword" (click)="routeChecker('helpdesk')">{{
              "userLogin.forgotPassword" | translate
            }}</a>
            <a
              class="forgetBtn"
              *ngIf="forgetPassword"
              (click)="forgetPassword = false"
              >{{ "userLogin.alreadyHaveAccount" | translate }}</a
            >
          </p>

          <button class="subBtn" type="submit">
            {{ "userLogin.login" | translate }}
          </button>

          <div class="login_footer_wrapper">
            <p>
              {{ "userLogin.dontHaveAccount" | translate }}
              <a (click)="routeChecker('sign-up')" class="signupBtn">{{
                "userLogin.register" | translate
              }}</a>
              <!-- <button (click)="signUp()">signup</button> -->
            </p>
          </div>
        </div>
      </div>
    </section>
  </form>
</section>
