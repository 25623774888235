import { UserToken } from './user-token';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from './user';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent {
  @ViewChild('theForm') form: FormGroup;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  user: User = new User('', '');
  subscrition: Subscription | undefined;
  returnTo: string = '/dashboard';
  code: string;
  forgetPassword: boolean = false;
  confirmPassword:string

  constructor(
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    activateRoute: ActivatedRoute,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    activateRoute.queryParams.subscribe({
      next: (queryParams) => {
        this.returnTo = queryParams['returnTo'] || this.returnTo;
      },
    });
  }
  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');
    this.router.navigate([`${selectedLang}/${url}`]);
  }

  onSubmit(): void {
    if (!this.forgetPassword) {
      // console.log('UserLoginComponent:', this.user);
      this.userService.login(this.user).subscribe({
        next: (userToken) => {
          // console.log('LOGINuserToken:', userToken, this.returnTo);
          if(userToken.token){
            this.translate.get('userLogin.toastrWelcome').subscribe((data1: string) => {
              this.translate.get('userLogin.toastrSuccess').subscribe((data2: string) => {
                this.toastr.success(data1,data2);
              });
            });

            this.routeChecker(this.returnTo)
          }else{
            this.translate.get('userLogin.toastrErrorCredential').subscribe((data1: string) => {
              this.translate.get('userLogin.toastrError').subscribe((data2: string) => {
                this.toastr.error(data1,data2);
              });
            });
          }
        },
        complete: () => console.log('LOGIN complete:'),
        error: (e) =>{
          this.translate.get('userLogin.toastrErrorCredential').subscribe((data1: string) => {
            this.translate.get('userLogin.toastrError').subscribe((data2: string) => {
              this.toastr.error(data1,data2);
            });
          });

          console.log('LOGIN error:', e)
        }
      });
    } else {
      this.userService.masterUserRegistration(this.user).subscribe((data) => {
        if (data) {
          this.translate.get('userLogin.alertChangePasswd').subscribe((data2: string) => {
            alert(
              data2
            );
          });
          this.routeChecker('login')
        }
      });
    }
  }

  emailError: string = "";
  errEmailX:  number = 0;
  errEmailY:  number = 0;
  isEmailValid(event: any){
    this.emailError = "";
    this.errEmailX = 0;
    this.errEmailY = 0;

    this.form.controls['login'].setErrors(null);
    var element = this.elementRef.nativeElement.querySelector("#login");

    if ( element?.offsetParent ){
      this.errEmailX = element?.offsetParent.getBoundingClientRect().x;
      this.errEmailY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errEmailX = Math.abs(this.errEmailX - element.getBoundingClientRect().x);
    this.errEmailY = Math.abs(this.errEmailY - element.getBoundingClientRect().y) - 25;

    //console.log(event);
    if ( event.length < 4 ){
      this.emailError = "userLogin.validator.email"
      this.form.controls['login'].setErrors({'incorrect': true});
    }

    if ( event.length > 100 ){
      this.emailError = "userLogin.validator.email"
      this.form.controls['login'].setErrors({'incorrect': true});
    }

    //if ( ! /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(event) ) {
    //  this.emailError = "userLogin.validator.emailRegex";
    //  this.form.controls['login'].setErrors({'incorrect': true});
    //}

    return this.emailError;
  }

  psswdInvalid: string = "";
  errPsswdX:  number = 0;
  errPsswdY:  number = 0;
  isPasswordValid(event: any){
    this.psswdInvalid = "";
    this.errPsswdX = 0;
    this.errPsswdY = 0;

    this.form.controls['password'].setErrors(null);
    var element = this.elementRef.nativeElement.querySelector("#password");

    if ( element?.offsetParent ){
      this.errPsswdX = element?.offsetParent.getBoundingClientRect().x;
      this.errPsswdY = element?.offsetParent.getBoundingClientRect().y;
    }

    this.errPsswdX = Math.abs(this.errPsswdX - element.getBoundingClientRect().x);
    this.errPsswdY = Math.abs(this.errPsswdY - element.getBoundingClientRect().y) - 25;

    if ( event.length < 3 ){
      this.psswdInvalid = "userLogin.validator.password"
      this.form.controls['password'].setErrors({'incorrect': true});
      return;
    }

    if ( event.length > 100 ){
      this.psswdInvalid = "userLogin.validator.password"
      this.form.controls['password'].setErrors({'incorrect': true});
      return;
    }

    return this.psswdInvalid;
  }

}
