<section
  class="smallHero"
  style="background-image: url(../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <div class="smallHero__title site__title">
      {{ "plans.create.title" | translate }}
    </div>
  </div>
</section>

<div class="formTemplate requestList">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="formCard">
          <div class="formCard__body">
            <div class="header">
              <h3 class="formCard__body-title">
                {{ "plans.create.title2" | translate }}
                <span style="color: #00bf63">{{ clientData?.name }}</span>
                {{ "plans.by" | translate }}
                <span style="color: #00bf63">{{ user?.fullName }}</span>
              </h3>
              <a
                (click)="routeChecker('plans')"
                class="button theme-btn-green"
                [title]="'plans.create.tooltip.viewBtn' | translate"
              >
                {{ "plans.create.btnView" | translate }}
              </a>
            </div>
            <div *ngIf="consumptionPlan">
              <div class="form-group">
                <label for="fName">{{
                  "plans.create.planName" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  [placeholder]="'plans.create.namePlaceholder' | translate"
                  aria-label="PlanName"
                  aria-describedby="basic-addon1"
                  minlength="3"
                  maxlength="50"
                  required="true"
                  [(ngModel)]="consumptionPlan.name"
                  [title]="'plans.create.tooltip.name' | translate"
                />
              </div>
              <div class="form-group">
                <label for="fName">{{
                  "plans.create.planDescription" | translate
                }}</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  [(ngModel)]="consumptionPlan.description"
                  minlength="3"
                  maxlength="1000"
                  required="true"
                  [placeholder]="'plans.create.descPlaceholder' | translate"
                  [title]="'plans.create.tooltip.description' | translate"
                >
                </textarea>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ "plans.create.planType" | translate }}</label>
                    <div class="row">
                      <div class="col-lg-5 col-md-6">
                        <span class="tooltipBlock">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              [disabled]="
                                consumptionPlan?.status === 'defined' ||
                                consumptionPlan?.status == 'new'
                              "
                              id="DayPlan"
                              [checked]="consumptionPlan.plan_type === 'day'"
                              (change)="onPlanTypeChange('day')"
                            />
                            <label class="form-check-label" for="DayPlan">
                              {{ "plans.create.dayPlan" | translate }}
                            </label>
                          </div>
                          <div class="tooltip">
                            {{ "plans.create.tooltip.dayPlan" | translate }}
                          </div>
                        </span>
                      </div>
                      <div class="col-lg-5 col-md-6">
                        <span class="tooltipBlock">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              [disabled]="
                                consumptionPlan?.status === 'defined' ||
                                consumptionPlan?.status == 'new'
                              "
                              id="IntradayPlan"
                              [checked]="
                                consumptionPlan.plan_type === 'intraday'
                              "
                              (change)="onPlanTypeChange('intraday')"
                            />
                            <label class="form-check-label" for="IntradayPlan">
                              {{ "plans.create.intradayPlan" | translate }}
                            </label>
                          </div>
                          <div class="tooltip">
                            {{
                              "plans.create.tooltip.intradayPlan" | translate
                            }}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ "plans.create.interval" | translate }}</label>
                    <select
                      class="btn form-control px-2 text-left"
                      id="myEnergyDropDwn"
                      name="myEnergyDropDwn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [(ngModel)]="consumptionPlan.interval"
                    >
                      <option
                        selected
                        style="display: none; color: gray"
                        value=""
                      >
                        {{ "plans.create.tooltip.interval" | translate }}
                      </option>
                      <option value="60">
                        {{ "plans.create.hourly" | translate }}
                      </option>
                      <option value="60">
                        {{ "plans.create.halfHourly" | translate }}
                      </option>
                      <option value="60">
                        {{ "plans.create.quarterly" | translate }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ "plans.create.unit" | translate }}</label>
                    <select
                      class="btn form-control px-2 text-left"
                      id="myEnergyDropDwn"
                      name="myEnergyDropDwn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [disabled]="
                        consumptionPlan?.status === 'defined' ||
                        consumptionPlan?.status == 'new'
                      "
                      [(ngModel)]="consumptionPlan.unit"
                    >
                      <option selected style="display: none" value="">
                        {{ "plans.create.tooltip.unit" | translate }}
                      </option>
                      <option value="kWh">kWh</option>
                      <option value="MWh">MWh</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Select Client Business Unit:</label>
                    <select
                      class="btn form-control px-2 text-left"
                      id="myEnergyDropDwn"
                      name="myEnergyDropDwn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [(ngModel)]="consumptionPlan.businessunit_id"
                      (ngModelChange)="filterLocation($event)"
                    >
                      <option
                        selected
                        style="display: none; color: gray"
                        value=""
                      >
                        Select Client Business Unit of plan...
                      </option>
                      <option *ngFor="let item of businessUnits" [value]="item.id">
                        {{ capitalizeFirstLetter(item.identifier) }}
                      </option>

                    </select>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Select Client Location:</label>
                    <select
                      class="btn form-control px-2 text-left"
                      id="myEnergyDropDwn"
                      name="myEnergyDropDwn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [(ngModel)]="consumptionPlan.clientlocation_id"
                    >
                      <option selected style="display: none" value="">
                        Select Client Location of plan...
                      </option>
                      <option *ngFor="let item of locations" [value]="item.id">
                        {{ capitalizeFirstLetter(item.identifier) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>



            <div
              class="row errorFormBlock"
              style="justify-content: space-evenly"
              [hidden]="
                consumptionPlan?.status !== 'defined' &&
                consumptionPlan?.status !== 'new'
              "
            >
              <h4>
                {{ "plans.create.createdOn" | translate }}
                {{ consumptionPlan?.created_at }}
              </h4>
              <h4>
                {{ "plans.create.updatedOn" | translate }}
                {{ consumptionPlan?.updated_at }}
              </h4>
            </div>
            <div
              class="errorFormBlock"
              [hidden]="
                consumptionPlan?.status !== 'defined' &&
                consumptionPlan?.status !== 'new'
              "
              style="margin-top: 15px"
            >
              <h4>
                {{ "plans.create.note" | translate }}
              </h4>
            </div>
          </div>
          <div class="formCard__footer">
            <button
              type="button"
              class="btn button subBtn"
              [disabled]="!consumptionPlan?.name"
              (click)="submitPlan()"
            >
              {{
                planId
                  ? ("plans.create.updatePlan" | translate)
                  : ("plans.create.createPlan" | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #dialogTemplate>
  <h1
    mat-dialog-title
    style="
      font-weight: 600;
      font-size: 22px;
      border-bottom: 2px solid rgb(209, 209, 209);
    "
  >
    {{ "plans.create.dialog.dialog1" | translate
    }}<span style="color: #00bf63">
      {{ "plans.create.dialog.dialog2" | translate }}</span
    >
  </h1>
  <mat-dialog-content class="dialog-content">
    <h5 mat-dialog-title style="font-weight: 500">
      {{ "plans.create.dialog.dialog3" | translate }}
      {{ consumptionPlan?.name }}
      {{ "plans.create.dialog.dialog4" | translate }} {{ clientData?.name }}
      {{ "plans.create.dialog.dialog5" | translate }}
      <br />
      {{ "plans.create.dialog.dialog6" | translate }}
    </h5>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: space-between; align-content: center"
  >
    <button class="theme-btn" (click)="createPlan('no')">
      {{ "plans.create.dialog.btnNo" | translate }}
    </button>
    <button class="theme-btn-dark" (click)="createPlan('yes')">
      {{ "plans.create.dialog.btnYes" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
