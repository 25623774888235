<section
  class="smallHero"
  style="background-image: url(../../assets/images/PowerStation.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col smallHero__title site__title">
        {{ "plans.title" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col smallHero__title site__title2">
        {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}
      </div>
    </div>
  </div>
</section>

<div class="requestList">
  <div class="container">
    <div class="userListing">
      <div class="container">
        <div class="userListing__card">
          <h5>{{ "plans.dayPlans" | translate }}</h5>
          <div class="userListing__card-head">
            <mat-form-field>
              <mat-label>{{ "plans.search" | translate }}</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event, 'day')"
                placeholder="Ex. iD"
                [title]="'plans.tooltip.search' | translate"
              />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>
            </mat-form-field>
            <div style="display: flex; gap: 10px">
              <a
                (click)="
                  dayConsumptionPlans.length === 0
                    ? null
                    : openFormatDialog('day')
                "
                [ngClass]="{
                  'button theme-btn-green': dayConsumptionPlans.length != 0,
                  disabledExport: dayConsumptionPlans.length === 0
                }"
                [title]="'plans.tooltip.export' | translate"
              >
                {{ "plans.exportBtn" | translate }}
              </a>

              <a
                (click)="redirectToPlanCreation('day')"
                class="button theme-btn-green"
                [title]="'plans.tooltip.add' | translate"
                >{{ "plans.addNewBtn" | translate }}</a
              >
            </div>
          </div>

          <div class="text-center mt-3" *ngIf="loading || loadingDay">
            <div class="spinner-border text-info" role="status"></div>
          </div>

          <div *ngIf="errorDay != ''" class="text-center mt-3 network-error">
            {{ errorDay | translate }}
          </div>

          <div
            style="color: #038846; font-weight: 500"
            *ngIf="
              !loadingDay &&
              dayPlansDataSource!.data!.length === 0 &&
              errorDay === ''
            "
            class="text-center mt-3"
          >
            {{ "plans.noPlans" | translate }}
          </div>

          <div
            class="userListing__table"
            *ngIf="!loading && dayPlansDataSource!.data!.length > 0"
          >
            <table
              mat-table
              [dataSource]="dayPlansDataSource"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.name" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ capitalizeFirstLetter(element.name) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="interval">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.interval" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ "plans.day" | translate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.startDate" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.startDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.endDate" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.endDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="totalCost">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.purchaseCost" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    transformCost(element.consumption_cost_calculated) ||
                      "plans.notAvailable" | translate
                  }}{{ checkCalculatedCost(element) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.createdBy" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getCreatedByName(element.created_by) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.createdAt" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updatedBy">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.updatedBy" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getCreatedByName(element.updated_by) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updated_at">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.updatedAt" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.updated_at }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.status" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ "plans.statusValue." + element.status | translate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.actions" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="actionsBtn">
                    <button
                      type="button"
                      class="borderBtn"
                      data-toggle="modal"
                      data-target="#viewModal"
                      (click)="viewModal(element)"
                      [title]="'plans.tooltip.actions.view' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="borderBtn"
                      (click)="generateCSV(element)"
                      [title]="'plans.tooltip.actions.download' | translate"
                    >
                      <svg
                        fill="currentColor"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Complete">
                          <g id="download">
                            <g>
                              <path
                                d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />

                              <g>
                                <polyline
                                  data-name="Right"
                                  fill="none"
                                  id="Right-2"
                                  points="7.9 12.3 12 16.3 16.1 12.3"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />

                                <line
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  x1="12"
                                  x2="12"
                                  y1="2.7"
                                  y2="14.2"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      class="borderBtn"
                      (click)="editConsumptionPlan(element.id)"
                      [title]="'plans.tooltip.actions.edit' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
                        />
                      </svg>
                    </button>
                    <button
                      class="redBtn"
                      (click)="openCofirmationDialog(element)"
                      [title]="'plans.tooltip.actions.delete' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsDayPlans"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsDayPlans"
              ></tr>
            </table>
          </div>
          <mat-paginator
            #dayPaginator
            [pageSize]="paginatorValues[0][0]"
            [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true"
            [length]="paginatorValues[0][3]"
            [pageIndex]="paginatorValues[0][1]"
            (page)="handlePage($event, 0)"
          ></mat-paginator>
          <p *ngIf="dayPlanDisclaimer" style="color: #038846; font-weight: 500">
            {{ "plans.notCostMsg" | translate }}
          </p>
        </div>
      </div>
    </div>

    <div class="userListing">
      <div class="container">
        <div class="userListing__card">
          <h5>{{ "plans.intraDayPlans" | translate }}</h5>
          <div class="userListing__card-head">
            <mat-form-field>
              <mat-label>{{ "plans.search" | translate }}</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event, 'intraday')"
                placeholder="Ex. iD"
                [title]="'plans.tooltip.search' | translate"
              />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                />
              </svg>
            </mat-form-field>
            <div style="display: flex; gap: 10px">
              <a
                (click)="
                  intradayConsumptionPlans.length === 0
                    ? null
                    : openFormatDialog('intraday')
                "
                [ngClass]="{
                  'button theme-btn-green':
                    intradayConsumptionPlans.length != 0,
                  disabledExport: intradayConsumptionPlans.length === 0
                }"
                [title]="'plans.tooltip.export' | translate"
              >
                {{ "plans.exportBtn" | translate }}
              </a>
              <a
                (click)="redirectToPlanCreation('intraday')"
                class="button theme-btn-green"
                [title]="'plans.tooltip.add' | translate"
                >{{ "plans.addNewBtn" | translate }}</a
              >
            </div>
          </div>

          <div class="text-center mt-3" *ngIf="loading || loadingIntraday">
            <div class="spinner-border text-info" role="status"></div>
          </div>

          <div
            *ngIf="errorIntraday != ''"
            class="text-center mt-3 network-error"
          >
            {{ errorIntraday | translate }}
          </div>

          <div
            style="color: #038846; font-weight: 500"
            *ngIf="
              !loadingIntraday &&
              intradayPlansDataSource!.data!.length === 0 &&
              errorIntraday === ''
            "
            class="text-center mt-3"
          >
            {{ "plans.noPlans" | translate }}
          </div>

          <div
            class="userListing__table"
            *ngIf="!loading && intradayPlansDataSource.data.length > 0"
          >
            <table
              mat-table
              [dataSource]="intradayPlansDataSource"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.name" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" style="width: 300px">
                  {{ capitalizeFirstLetter(element.name) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="interval">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.interval" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    "plans.intervalValue." + getIntervals(element.interval)
                      | translate
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.startDate" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.startDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.endDate" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.endDate }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalCost">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.purchaseCost" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    transformCost(element.consumption_cost_calculated) ||
                      "plans.notAvailable" | translate
                  }}{{ checkCalculatedCost(element) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.createdBy" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getCreatedByName(element.created_by) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.createdAt" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updatedBy">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.updatedBy" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getCreatedByName(element.updated_by) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updated_at">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.updatedAt" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.updated_at }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.status" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ "plans.statusValue." + element.status | translate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.actions" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="actionsBtn">
                    <button
                      type="button"
                      class="borderBtn"
                      data-toggle="modal"
                      data-target="#viewModal"
                      (click)="viewModal(element)"
                      [title]="'plans.tooltip.actions.view' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="borderBtn"
                      (click)="generateCSV(element)"
                      [title]="'plans.tooltip.actions.download' | translate"
                    >
                      <svg
                        fill="currentColor"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Complete">
                          <g id="download">
                            <g>
                              <path
                                d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />

                              <g>
                                <polyline
                                  data-name="Right"
                                  fill="none"
                                  id="Right-2"
                                  points="7.9 12.3 12 16.3 16.1 12.3"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />

                                <line
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  x1="12"
                                  x2="12"
                                  y1="2.7"
                                  y2="14.2"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      class="borderBtn"
                      (click)="editConsumptionPlan(element.id)"
                      [title]="'plans.tooltip.actions.edit' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
                        />
                      </svg>
                    </button>
                    <button
                      class="redBtn"
                      (click)="openCofirmationDialog(element)"
                      [title]="'plans.tooltip.actions.delete' | translate"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            #intradayPaginator
            [pageSize]="paginatorValues[1][0]"
            [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true"
            [length]="paginatorValues[1][3]"
            [pageIndex]="paginatorValues[1][1]"
            (page)="handlePage($event, 1)"
          ></mat-paginator>
          <p
            *ngIf="intradayPlanDisclaimer"
            style="color: #038846; font-weight: 500"
          >
            {{ "plans.notCostMsg" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogTemplate2>
  <h1
    mat-dialog-title
    style="
      font-weight: 600;
      font-size: 22px;
      border-bottom: 1px solid rgb(201, 201, 201);
    "
  >
    {{ "plans.exportFormat" | translate }}
  </h1>
  <mat-dialog-content class="dialog-content">
    <div class="form-group">
      <select
        size="2"
        class="btn form-control px-2 text-left"
        id="myEnergyDropDwn"
        name="myEnergyDropDwn"
        type="button"
        aria-haspopup="true"
        aria-expanded="false"
        [(ngModel)]="exportType"
      >
        <option selected value="csv">CSV</option>
        <option value="xlsx">XLSX</option>
        <!--
        <option selected style="display: none; color: gray" value="">
          {{ "plans.exportFormat" || translate }}
        </option>
      
        <option value="csv">CSV</option> 
      --></select>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-content: center"
  >
    <button class="theme-btn-yellow" mat-dialog-close (click)="exportPlans()">
      {{ "plans.exportBtn" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #dialogTemplate>
  <h1
    mat-dialog-title
    style="
      font-weight: 600;
      font-size: 22px;
      border-bottom: 1px solid rgb(201, 201, 201);
    "
  >
    {{ "plans.confirmation" | translate }}
  </h1>
  <mat-dialog-content class="dialog-content">
    <h5 mat-dialog-title style="font-weight: 500">
      {{ "plans.sureToDelete" | translate }}
      <br />
      {{ selectedData.name }}{{ "plans.asAPlan" | translate }}
    </h5>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: space-between; align-content: center"
  >
    <button class="theme-btn-yellow" mat-dialog-close>
      {{ "plans.no" | translate }}
    </button>
    <button class="theme-btn-red" (click)="deleteConsumptionPlan()">
      {{ "plans.yes" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<div
  class="modal fade detailsModel"
  id="viewModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="viewModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewModalLabel">
          {{ "plans.details.title" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="clientDetailsBlock"
          *ngIf="selectedPlan?.plan_type == 'day'"
        >
          <div class="PlanBox">
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.name" | translate }}</label>
              <p>{{ selectedPlan?.name }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{
                "plans.details.description" | translate
              }}</label>
              <p>{{ selectedPlan?.description }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.client" | translate }}</label>
              <p>
                {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}
              </p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.date" | translate }}</label>
              <p>
                {{ selectedPlan?.consumptions[0]?.day }}/{{
                  selectedPlan?.consumptions[0]?.month
                }}/{{ selectedPlan?.consumptions[0]?.year }} -
                {{
                  selectedPlan?.consumptions[
                    selectedPlan?.consumptions.length - 1
                  ]?.day
                }}/{{
                  selectedPlan?.consumptions[
                    selectedPlan?.consumptions.length - 1
                  ]?.month
                }}/{{
                  selectedPlan?.consumptions[
                    selectedPlan?.consumptions.length - 1
                  ]?.year
                }}
              </p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.unit" | translate }}</label>
              <p>{{ selectedPlan?.unit }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.interval" | translate }}</label>
              <p>{{ selectedPlan?.interval }} minutes</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.plCons" | translate }}</label>
              <p>{{ totalConsumption }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.avgCons" | translate }}</label>
              <p>{{ avgConsumption }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.cost" | translate }}</label>
              <p>
                {{ transformCost(selectedPlan?.consumption_cost_calculated) }}
              </p>
            </div>
          </div>

          <!-- <div class="row p-3"></div> -->
          <body class="graf img-fluid" #graf></body>

          <!--
          <div *ngIf="allDataDownloaded">
            <ngx-uplot
              [data]="plotData"
              [options]="plotOptions"
              [legend]="true"
            >
            </ngx-uplot>
          -->
          <div
            echarts
            [options]="plotOptions"
            [loading]="isLoading"
            theme="macarons"
            class="day-chart"
            id="day-chart"
          ></div>

          <div class="row p-3"></div>

          <div class="IntraDayEditForm__table">
            <h4>{{ "plans.details.consumptions.title" | translate }}</h4>
            <table
              mat-table
              [dataSource]="selectedPlanConsumptions"
              class="mat-elevation-z8"
            >
              <!-- Login Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.date" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.day }}/{{ element.month }}/{{ element.year }}
                </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="consumption">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.cons" | translate }} ({{
                    selectedPlan?.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.consumption }}
                </td>
              </ng-container>

              <!-- Interval Column -->
              <ng-container matColumnDef="minimum">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.min" | translate }} ({{
                    selectedPlan?.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.min }}
                </td>
              </ng-container>

              <!-- Role Column -->
              <ng-container matColumnDef="maximum">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.max" | translate }} ({{
                    selectedPlan?.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.max }}
                </td>
              </ng-container>

              <ng-container matColumnDef="totalCost">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.total" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ transformCost(element.consumption_cost_calculated) }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsDayPlan"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsDayPlan"
              ></tr>
            </table>
          </div>
        </div>
        <div
          class="clientDetailsBlock"
          *ngIf="selectedPlan?.plan_type == 'intraday'"
        >
          <div class="PlanBox">
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.name" | translate }}</label>
              <p>{{ selectedPlan?.name }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{
                "plans.details.description" | translate
              }}</label>
              <p>{{ selectedPlan?.description }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.date" | translate }}</label>
              <p>
                {{ selectedPlan?.consumptions[0]?.day }}/{{
                  selectedPlan?.consumptions[0]?.month
                }}/{{ selectedPlan?.consumptions[0]?.year }}
              </p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.unit" | translate }}</label>
              <p>{{ selectedPlan?.unit }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.interval" | translate }}</label>
              <p>{{ selectedPlan?.interval }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.plCons" | translate }}</label>
              <p>{{ totalConsumption }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{ "plans.details.avgCons" | translate }}</label>
              <p>{{ avgConsumption }}</p>
            </div>
            <div class="PlanBox__inner">
              <label for="">{{
                "plans.details.consumptions.total" | translate
              }}</label>
              <p>
                {{ transformCost(selectedPlan?.consumption_cost_calculated) }}
              </p>
            </div>
          </div>

          <body class="graf img-fluid" #graf></body>

          <!--
          <div *ngIf="allDataDownloaded">
            <ngx-uplot
              [data]="plotData"
              [options]="plotOptions"
              [legend]="true"
            >
            </ngx-uplot>
          -->
          <div
            echarts
            [options]="plotOptions"
            [loading]="isLoading"
            theme="macarons"
            class="day-chart"
            id="day-chart"
          ></div>

          <div class="row p-3"></div>

          <div class="IntraDayEditForm__table">
            <h4>Consumptions:</h4>
            <table
              mat-table
              [dataSource]="selectedPlanConsumptions"
              class="mat-elevation-z8"
            >
              <!-- Login Column -->
              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.time" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getHourName(element.hour) }}
                </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="consumption">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.cons" | translate }} ({{
                    selectedPlan.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.consumption }}
                </td>
              </ng-container>

              <!-- Interval Column -->
              <ng-container matColumnDef="minimum">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.min" | translate }} ({{
                    selectedPlan.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.min }}
                </td>
              </ng-container>

              <!-- Role Column -->
              <ng-container matColumnDef="maximum">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.max" | translate }} ({{
                    selectedPlan.unit
                  }})
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.max }}
                </td>
              </ng-container>

              <ng-container matColumnDef="totalCost">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "plans.details.consumptions.total" | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ transformCost(element.consumption_cost_calculated) }}
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsnIntradayPlanReview"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumnsnIntradayPlanReview
                "
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
