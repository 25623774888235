import { Component, OnInit } from '@angular/core';
import { AnalysisService } from '../analysis.service';
import { formatDate } from '@angular/common';
import { EChartsOption } from 'echarts';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Semaphore } from './semaphore-model';
import * as echarts from 'echarts';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-analysis-actual',
  templateUrl: './analysis-actual.component.html',
  styleUrls: ['./analysis-actual.component.scss'],
})
export class AnalysisActualComponent implements OnInit {
  timestamps: number[] = [];
  values: Array<Array<[number, number]>> = [[], [], []];
  pricesDownloaded: boolean[] = [false, false, false];
  allDataDownloaded: boolean = false;
  dateFrom: string;
  dateUntil: string;
  spotPrice: number = 0;
  forecastPrice: number = 0;
  trendRising: boolean;
  region: string = 'sk';

  isLoading = true;
  chartOption: EChartsOption;
  myChart: any; // Define myChart
  totalTimeRange: number; // Total time range (milliseconds)
  minZoomHours: number = 6; // Minimum zoom range in hours
  maxZoomHours: number = 6; // Maximum zoom range in hours

  semaphoreColors = [Semaphore.Blank, Semaphore.Static, Semaphore.Blank];
  semaphoreValues = [0, 0, 0];

  lastHour: any;
  highlightRegions: Array<Date> = [];

  constructor(private analysisService: AnalysisService, private toastr: ToastrService, private translate: TranslateService) {
    this.lastHour = new Date(); // Get current date and time
    this.lastHour.setMinutes(0, 0, 0);
    this.setInitialDates();
  }

  ngOnInit() {
    this.gatherPlotData();
  }

  setInitialDates() {
    let day = formatDate(new Date(), 'yyyy-MM-dd', 'en') + ' 00:00:00';
    let date = new Date(day);
    date.setDate(date.getDate() + 5);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate() - 8);
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
  }

  gatherPlotData() {
    this.semaphoreColors = [Semaphore.Blank, Semaphore.Static, Semaphore.Blank];
    this.semaphoreValues = [0, 0, 0];

    let actualMaxTime = 0;
    let actualMinTime = 0;
    let backcastMaxTime = 0;
    let forecastMaxTime = this.dateStringToUnix(this.dateUntil + ' 24:00:00');

    Promise.all([
      lastValueFrom(
        this.analysisService.getPricesActualData(
          this.dateFrom,
          this.dateUntil,
          this.region,
          false
        )
      ),
      lastValueFrom(
        this.analysisService.getPricesBackcastData(
          this.dateFrom,
          this.dateUntil,
          this.region,
          false
        )
      ),
      lastValueFrom(
        this.analysisService.getPricesForecastData(
          this.dateFrom,
          this.dateUntil,
          this.region,
          false
        )
      ),
    ]).then(([actualData, backcastData, forecastData]) => {
      //console.log("actual = ",actualData);
      //console.log("backcast = ", backcastData);
      //console.log("forecast", forecastData);

      if (actualData) this.processData(actualData, 0, 0, null, null); // this.processData(actualData, 0, 1, null);
      if (this.values[0][0][0]) actualMinTime = this.values[0][0][0];
      if (this.values[0][this.values[0].length - 1][0])
        actualMaxTime = this.values[0][this.values[0].length - 1][0];

      if (backcastData)
        this.processData(backcastData, 1, 0, actualMinTime, actualMaxTime); // this.processData(backcastData, 1, 1, maxTime);
      if (forecastData)
        this.processData(forecastData, 2, null, actualMaxTime, forecastMaxTime); // this.processData(forecastData, 2, 1, null);

      if (this.values[1][this.values[1].length - 1][0])
        backcastMaxTime = this.values[1][this.values[1].length - 1][0];

      if (backcastMaxTime < actualMaxTime) {
        this.processData(forecastData, 1, null, backcastMaxTime, actualMaxTime);
      }

      this.allDataDownloaded = true;
      this.calculateSpotAndForecastPrices();
      this.findHighlightRegions();

      this.totalTimeRange = this.calculateTotalTimeRange();
      this.setZoomRanges();
      this.updateChartOption();

      const chartDom = document.getElementById('demo-chart');
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.chartOption);

      this.myChart.on('dataZoom', this.handleDataZoom.bind(this));

      this.calculateSemaphor();
      this.isLoading = false;

      //console.log("values :", this.values);
    });
  }
  calculateTotalTimeRange() {
    const startTime = this.values[0][0][0] * 1000; // First timestamp in milliseconds
    const endTime = this.values[0][this.values[0].length - 1][0] * 1000; // Last timestamp in milliseconds
    return endTime - startTime;
  }

  setZoomRanges() {
    const totalTimeRangeHours = this.totalTimeRange / 3600000;
    this.minZoomHours = Math.min(6, totalTimeRangeHours * 0.6);
    this.maxZoomHours = Math.min(10, totalTimeRangeHours);
  }

  handleDataZoom(event: any) {
    if (event?.batch) {
      const start = event?.batch[0]?.start;
      const end = event?.batch[0]?.end;

      const timeRangePercentage = (end - start) / 100;
      const timeRangeInMilliseconds = timeRangePercentage * this.totalTimeRange;
      const timeRangeInHours = timeRangeInMilliseconds / 3600000;

      if (timeRangeInHours < this.minZoomHours) {
        // Prevent zooming in beyond the minimum zoom range
        const minZoomRangePercentage =
          ((this.minZoomHours * 3600000) / this.totalTimeRange) * 100;
        this.myChart.dispatchAction({
          type: 'dataZoom',
          start: start,
          end: start + minZoomRangePercentage,
          zoomLock: true,
        });
      } else if (timeRangeInHours > this.maxZoomHours) {
        // Allow zooming out beyond the maximum zoom range
        this.myChart.dispatchAction({
          type: 'dataZoom',
          start: start,
          end: end,
          zoomLock: false,
        });
      } else {
        // Within the allowed zoom range
        this.myChart.dispatchAction({
          type: 'dataZoom',
          start: start,
          end: end,
          zoomLock: false,
        });
      }
    }
  }

  updateChartOption() {
    let date = new Date();
    const tz = date.getTimezoneOffset() * 60000;

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          if (Array.isArray(params) && params.length > 0) {
            let date = new Date(params[0].value[0]);
            return `${formatDate(date, 'dd.MM.yyyy HH:mm', 'en')}<br/>
              ${params
                .map(
                  (param: any) =>
                    `${param.seriesName}: ${param.value[1]} Eur/MWh`
                )
                .join('<br/>')}`;
          }
          return '';
        },
      },
      legend: {
        data: ['Actual', 'Backcast', 'Forecast'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false,
          throttle: 100,
        },
        {
          show: false,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100,
          zoomLock: false,
          throttle: 100,
        },
      ],
      xAxis: {
        name: 'Date / Time',
        type: 'time',
        min: 'dataMin',
        max: 'dataMax',
        axisLabel: {
          formatter: (value: number) =>
            formatDate(new Date(value), 'dd.MM.yyyy HH:mm', 'en'),
          rotate: 35,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Eur / MWh',
        min: 'dataMin',
        max: 'dataMax',
      },
      series: [
        {
          name: 'Actual',
          type: 'line',
          symbol: 'none',
          data: this.values[0].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: 'black', width: 2 },
          itemStyle: { color: 'black' },
          smooth: true,
        },
        {
          name: 'Backcast',
          type: 'line',
          symbol: 'none',
          data: this.values[1].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: '#00bf63', width: 1 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true,
        },
        {
          name: 'Forecast',
          type: 'line',
          symbol: 'none',
          data: this.values[2].map((item) => [item[0] * 1000, item[1]]),
          lineStyle: { color: '#0083cf', width: 2 },
          itemStyle: { color: '#0083cf' },
          smooth: true,
        },
        {
          name: 'Current Hour',
          type: 'line',
          markArea: {
            data: [
              [
                {
                  xAxis: this.highlightRegions[0],
                  itemStyle: { color: '#0083cf', opacity: 0.25 },
                },
                { xAxis: this.highlightRegions[3] },
              ],
              [
                {
                  xAxis: this.highlightRegions[1],
                  itemStyle: { color: '#0083cf', opacity: 0.3 },
                },
                { xAxis: this.highlightRegions[2] },
              ],
            ],
          },
        },
      ],
    };
  }

  calculateSpotAndForecastPrices() {
    // Calculate spotPrice (latest actual price)
    if (this.values[0].length > 0) {
      this.spotPrice = this.values[0][this.values[0].length - 1][1];
    }

    // Calculate forecastPrice (first forecast price after the latest actual price)
    let lastForecastIndex = 0;

    if (this.values[2].length > 0) {
      const latestActualTime =
        this.values[0].length > 0
          ? this.values[0][this.values[0].length - 1][0]
          : 0;

      lastForecastIndex = this.values[2].findIndex(
        (item) => item[0] > latestActualTime
      );

      let pocet = 0;
      let forecastAvgPrice = 0;
      if (lastForecastIndex >= 0) {
        for (let i = lastForecastIndex; i < lastForecastIndex + 3; i++) {
          forecastAvgPrice = forecastAvgPrice + this.values[2][i][1];
          pocet++;
        }

        forecastAvgPrice = forecastAvgPrice / pocet;
      }

      if (forecastAvgPrice) {
        this.forecastPrice = Math.round(forecastAvgPrice * 100) / 100;
      }
    }

    // Calculate trendRising
    this.trendRising = this.forecastPrice > this.spotPrice;
  }

  processData(
    jsonData: any,
    valueIndex: number,
    priceIndex: number | null,
    limitMin: number | null,
    limitMax: number | null
  ): number {
    let myMaxTime: number = 0;
    let attrib: string = 'price';

    //const dateFromUnix = this.dateStringToUnix(this.dateFrom);
    //const dateUntilUnix = this.dateStringToUnix(this.dateUntil);

    //console.log("JsonData = ",jsonData);

    if (jsonData instanceof Array) {
      jsonData.forEach((element: any) => {
        let write: boolean = false;
        let time = this.dateStringToUnix(
          element?.year +
          '.' +
          element?.month +
          '.' +
          element?.day +
          ' ' +
          element?.hour +
          ':00:00'
        );

        if (time >= 0) {
          // Filter data within the specified date range
          write = true;

          if (limitMin !== null && limitMin > time) write = false;
          if (limitMax !== null && limitMax < time) write = false;

          if (write) {
            if (!this.timestamps.includes(time)) {
              this.timestamps.push(time);
              if (time > myMaxTime) myMaxTime = time;
            }

            if (priceIndex === null) {
              // if priceIndex is null, find first not null value
              for (let i = 0; i < 20; i++) {
                attrib = 'price';

                if (i > 0) attrib = attrib.concat(i.toString());
                if (element[attrib] && element[attrib] !== null) break;
              }
            } else {
              if (priceIndex > 0) attrib = 'price' + priceIndex.toString();
            }

            if (element[attrib]) {
              this.values[valueIndex].push([time, element[attrib] / 100]);
            }
          }
        }
      });
    }

    return myMaxTime;
  }

  findHighlightRegions() {
    this.highlightRegions[0] = new Date(
      this.lastHour.getTime() - 2 * 60 * 60 * 1000
    );

    this.highlightRegions[1] = this.lastHour;

    this.highlightRegions[2] = new Date(
      this.lastHour.getTime() + 1 * 60 * 60 * 1000
    );

    this.highlightRegions[3] = new Date(
      this.lastHour.getTime() + 3 * 60 * 60 * 1000
    );
  }

  calculateSemaphor() {
    // const lastHourUTC = this.lastHour.getTime() - this.lastHour.getTimezoneOffset() * 60000;
    const lastHourUTC = this.lastHour.getTime() / 1000
    let currentHourIndex = this.values[0].findIndex(
      (item) => item[0] >= lastHourUTC
    );

    this.semaphoreValues[0] = 0;
    this.semaphoreValues[1] = 0;
    this.semaphoreValues[2] = 0;

    if ( currentHourIndex >= 2){
      // Correctly calculate semaphore values
      this.semaphoreValues[0] = (this.values[0][currentHourIndex - 2][1] + this.values[0][currentHourIndex - 1][1]) / 2;
      this.semaphoreValues[1] = this.values[0][currentHourIndex][1];
      this.semaphoreValues[2] = (this.values[0][currentHourIndex + 1][1] + this.values[0][currentHourIndex + 2][1]) / 2;

      const semValueSpot = this.semaphoreValues[1] - this.semaphoreValues[0];
      if (semValueSpot > 1) this.semaphoreColors[0] = Semaphore.Red;
      else if (semValueSpot < -1) this.semaphoreColors[0] = Semaphore.Green;
      else this.semaphoreColors[0] = Semaphore.Yellow;

      const semValueFore = this.semaphoreValues[1] - this.semaphoreValues[2];
      if (semValueFore > 1) this.semaphoreColors[2] = Semaphore.Green;
      else if (semValueFore < -1) this.semaphoreColors[2] = Semaphore.Red;
      else this.semaphoreColors[2] = Semaphore.Yellow;

      const semValueTrend = semValueSpot - semValueFore;
      if (semValueTrend > 1) this.semaphoreColors[1] = Semaphore.Rising;
      else if (semValueTrend < -1) this.semaphoreColors[1] = Semaphore.Falling;
      else this.semaphoreColors[1] = Semaphore.Static;
    } else {
      this.translate.get('priceToday.validator.toastr.semafor1').subscribe((data1: string) => {
        this.translate.get('priceToday.validator.toastr.semafor2').subscribe((data2: string) => {
          this.toastr.error(data2,data1);
        });      
      });

      if ( this.semaphoreValues[1] === 0 ){
        this.spotPrice = -999;
      }
      if ( this.semaphoreValues[2] === 0 ){
        this.forecastPrice = -999;        
      }
    }
}

  showSemaphore() {
    console.log("Show semaphore");
  }


  setRegion() {
    this.analysisService.setRegion(this.region);
    this.resetPlotData();
    this.gatherPlotData();
  }

  resetPlotData() {
    this.timestamps = [];
    this.values = [[], [], [], []];
    this.pricesDownloaded = [false, false, false];
    this.allDataDownloaded = false;
    this.spotPrice = 0;
    this.forecastPrice = 0;
    this.trendRising = false;
    this.isLoading = true;
  }

  dateStringToUnix(dateText: string): number {
    const date = new Date(dateText);
    const userTZ = date.getTimezoneOffset() * 60000;

    return Math.floor((date.getTime() + userTZ) / 1000);
  }

  generateChart() {
    this.resetPlotData();
    this.gatherPlotData();
  }
}
