<header [ngClass]="{ header: showHeaderBar, activeMenu: showMenu }">
  <div class="container">
    <div class="header__inner">
      <a class="header__logo" href="/">
        <img
          src="../assets/logo/logo-green.png"
          width="60"
          height="60"
          class="d-inline-block align-top"
          alt=""
        />
        <div>My<span>Energy v.1</span></div>
      </a>

      <nav class="navbar">
        <a (click)="routeChecker('news')">{{
          "header.navbar.news" | translate
        }}</a>
        <a (click)="routeChecker('home')">{{
          "header.navbar.about" | translate
        }}</a>
        <a (click)="routeChecker('dashboard')">{{
          "header.navbar.dashboard" | translate
        }}</a>
        <a (click)="routeChecker('analysis/actual')">{{
          "header.navbar.priceToday" | translate
        }}</a>
        <a
          (click)="routeChecker('request-a-presentation')"
          *ngIf="!isLoggedIn"
          >{{ "header.navbar.presentation" | translate }}</a
        >
        <a (click)="routeChecker('helpdesk')" *ngIf="isLoggedIn">{{
          "header.navbar.helpdesk" | translate
        }}</a>
        <a (click)="routeChecker('contactUs')">{{
          "header.navbar.contactUs" | translate
        }}</a>

        <div class="dropdown loginDropdown" *ngIf="!(loggedIn$ | async)">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="loginMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Click
          </button>
          <div
            class="dropdown-menu dropdown-menu__links"
            aria-labelledby="loginMenuButton"
          >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              (click)="routeChecker('login')"
              >{{ "header.navbar.signIn" | translate }}</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              (click)="routeChecker('sign-up')"
              >{{ "header.navbar.register" | translate }}</a
            >
          </div>
        </div>

        <button
          class="signup-btn hiden-btn"
          (click)="switchToLogin()"
          *ngIf="!(loggedIn$ | async)"
        >
          {{ "header.navbar.signIn" | translate }}
        </button>
        <button
          class="signup-btn hiden-btn"
          (click)="switchToRegister()"
          *ngIf="!(loggedIn$ | async)"
        >
          {{ "header.navbar.register" | translate }}
        </button>
      </nav>

      <div class="navbar-action">
        <div class="mobileBtns">
          <button class="navToggle" (click)="onNavToggleClick()">
            <span></span>
          </button>
        </div>

        <mat-form-field appearance="fill" class="langBlock">
          <mat-select
            [(ngModel)]="selectedLanguage"
            (selectionChange)="changeLanguage($event)"
            (click)="mojPokus()"
          >
            <mat-select-trigger>
              <img
                [src]="selectedLanguage?.image"
                alt=""
                height="20"
                width="30"
              />
            </mat-select-trigger>
            <mat-option *ngFor="let lang of languageOptions" [value]="lang">
              <img [src]="lang.image" alt="" height="20" width="30" />
              {{ lang.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="userDropdown" *ngIf="loggedIn$ | async">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="togleUserDropdown()"
          >
            <span class="first-character">{{ getDisplayName() }}</span>
            <span class="hiden-text">{{ user.fullName || user.login }}</span>
          </button>

          <div
            class="dropdown-menu"
            [ngClass]="{ show: showUserDropdown }"
            aria-labelledby="dropdownMenuButton"
          >
            <div class="dropdown-menu__head">
              <span class="first-character">{{ getDisplayName() }}</span>
              {{ user.fullName || user.login }}
            </div>
            <div class="dropdown-menu__links">
              <a *ngIf="user?.role == 'master user'" (click)="clientUsers()">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                    /></svg></span
                >Client Users
              </a>
              <a
                *ngIf="user?.role == 'admin'"
                (click)="routeChecker('approval')"
              >
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                    /></svg></span
                >{{ "header.navbar.userDropdown.approval" | translate }}
              </a>
              <button
                class="signup-btn"
                *ngIf="loggedIn$ | async"
                (click)="logout()"
              >
                {{ "header.navbar.userDropdown.signOut" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="container2"></div>
