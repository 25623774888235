import { SpecificConditionsComponent } from './user/specific-conditions/specific-conditions.component';
import { DataExportServicesComponent } from './data-export-services/data-export-services.component';
import { Routes } from '@angular/router';
import { UserLoginComponent } from './user/user-login.component';
import { AnalysisActualComponent } from './analysis/analysis-actual/analysis-actual.component';
import { Analysis1dayComponent } from './analysis/analysis1day/analysis1day.component';
import { Analysis1weekComponent } from './analysis/analysis1week/analysis1week.component';
import { Forecast1weekComponent } from './forecast/forecast1week/forecast1week.component';
import { Forecast1monthComponent } from './forecast/forecast1month/forecast1month.component';
import { loggedInGuard } from './user/logged-in.guard';
import { Backcast1dayComponent } from './backcast/backcast1day/backcast1day.component';
import { Backcast1weekComponent } from './backcast/backcast1week/backcast1week.component';
import { Forecast14Component } from './forecast/forecast14/forecast14.component';
import { Order14DaysComponent } from './order/order14-days/order14-days.component';
import { OrderListComponent } from './order/order-request/order-list.component';
import { UpdateComponent } from './backcast/update/update.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlanCreateComponent as PlanCreateComponent } from './plan/create/plan-create.component';
import { PlanListComponent as PlanListComponent } from './plan/list/plan-list.component';
import { UsersComponent } from './admin/users/users.component';
import { ClientsComponent } from './admin/clients/clients.component';
import { OrderPredictionComponent } from './order/prediction/order-prediction.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UserComponent } from './admin/users/user/user.component';
import { ApprovalPageComponent } from './approval-page/approval-page.component';
import { ClientUserPasswordSetupComponent } from './user/client-user-password-setup/client-user-password-setup.component';
import { AllPlansComponent } from './plan/all-plans/all-plans.component';
import { RequestAPresentationComponent } from './request-a-presentation/request-a-presentation.component';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { HappenedYesterdayComponent } from './happened-yesterday/happened-yesterday.component';
import { HappenedWeekComponent } from './happened-yesterday/happened-week/happened-week.component';
import { HappenedMonthComponent } from './happened-yesterday/happened-month/happened-month.component';
import { BusinessUnitLocationsComponent } from './business-unit-locations/business-unit-locations.component';


export const routers: Routes = [
  { path: ':lang/login', component: UserLoginComponent, title: 'prihlasenie' },
  { path: ':lang/sign-up', component: SignUpComponent, title: 'prihlasenie' },
  { path: ':lang/dashboard', component: DashboardComponent },

  // Analysis Module Routes
  { path: ':lang/analysis', pathMatch: 'full', redirectTo: ':lang/analysis/actual' },
  { path: ':lang/analysis/actual', component: AnalysisActualComponent},
  { path: ':lang/analysis/1day', component: Analysis1dayComponent},
  { path: ':lang/analysis/1week', component: Analysis1weekComponent},

  // Forecast Module Routes
  { path: ':lang/forecast', pathMatch: 'full', redirectTo: ':lang/forecast/1week' },
  { path: ':lang/forecast/1week', component: Forecast1weekComponent, canActivate: [loggedInGuard] },
  { path: ':lang/forecast/1month', component: Forecast1monthComponent, canActivate: [loggedInGuard] },
  { path: ':lang/forecast/14days', component: Forecast14Component, canActivate: [loggedInGuard] },

  // Backcast Module Routes
  { path: ':lang/backcast', pathMatch: 'full', redirectTo: ':lang/backcast/1day' },
  { path: ':lang/backcast/1day', component: Backcast1dayComponent, canActivate: [loggedInGuard] },
  { path: ':lang/backcast/1week', component: Backcast1weekComponent, canActivate: [loggedInGuard] },
  { path: ':lang/backcast/update', component: UpdateComponent, canActivate: [loggedInGuard] },

  // Plan Module Routes
  { path: ':lang/plans', component: AllPlansComponent, canActivate: [loggedInGuard] },
  { path: ':lang/plan/create/:id', component: PlanCreateComponent, canActivate: [loggedInGuard] },
  { path: ':lang/plan/list/:id', component: PlanListComponent, canActivate: [loggedInGuard] },

  // Order Module Routes
  { path: ':lang/order', pathMatch: 'full', redirectTo: ':lang/order/list' },
  { path: ':lang/order/14days', component: Order14DaysComponent, canActivate: [loggedInGuard] },
  { path: ':lang/order/14days/:id', component: Order14DaysComponent, canActivate: [loggedInGuard] },
  { path: ':lang/order/list', component: OrderListComponent, canActivate: [loggedInGuard] },
  { path: ':lang/order/prediction', component: OrderPredictionComponent, canActivate: [loggedInGuard] },

  // Admin Module Routes
  { path: ':lang/admin', pathMatch: 'full', redirectTo: ':lang/admin/users'},
  { path: ':lang/admin/users', component: UsersComponent, canActivate: [loggedInGuard]  },
  { path: ':lang/admin/user/:id', component: UserComponent, canActivate: [loggedInGuard]  },
  { path: ':lang/admin/clients', component: ClientsComponent, canActivate: [loggedInGuard] },

  //Happened Past Routes
  { path: ':lang/happened-yesterday', component: HappenedYesterdayComponent,canActivate: [loggedInGuard] },
  { path: ':lang/happened-week', component: HappenedWeekComponent,canActivate: [loggedInGuard] },
  { path: ':lang/happened-month', component: HappenedMonthComponent,canActivate: [loggedInGuard] },

  //Data export service
  { path: ':lang/data-export-service', component: DataExportServicesComponent, canActivate: [loggedInGuard] },

  // Other Routes
  { path: ':lang/home', component: LandingPageComponent },
  { path: ':lang/news', component: NewsPageComponent },
  { path: ':lang/business-units-locations', component: BusinessUnitLocationsComponent },
  { path: ':lang/request-a-presentation', component: RequestAPresentationComponent },
  { path: ':lang/helpdesk', component: HelpdeskComponent },
  { path: ':lang/specific-conditions', component: SpecificConditionsComponent },
  { path: ':lang/contactUs', component: ContactUsComponent },
  { path: ':lang/approval', component: ApprovalPageComponent,canActivate: [loggedInGuard] },
  { path: ':lang/termsOfService', component: TermsOfServiceComponent },
  { path: ':lang/activity-logs', component: ActivityLogsComponent },
  { path: ':lang/oneTimePasswordSetup', component: ClientUserPasswordSetupComponent },
  { path: '', pathMatch: 'full', redirectTo: `${sessionStorage.getItem('language')}/home`, },
  { path: '**', pathMatch: 'full', redirectTo: `${sessionStorage.getItem('language')}/home`, },
];


